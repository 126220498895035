<template>
  <base-view title="Saldos por unidades administrativas">
    <div class="container-fluid mt-4">
      <div class="card">
        <div class="card-body">
          <UnidadAdministrativa/>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import UnidadAdministrativa from '@/components/Saldos/UnidadAdministrativa'

export default {
  name: 'UnidadAdministrativaView',

  components: {
    UnidadAdministrativa
  }
}
</script>
